@layer components {
  .react-datepicker__triangle {
    transform: translateX(125px) !important;
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    @apply block w-full;
  }
}
