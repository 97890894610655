@layer layer {
  .blog-teaser {
    &:hover {
      .link {
        &::before {
          @apply !w-3;
        }
      }
    }
  }
}
