@layer components {
  .paragraph--code {
    @apply flex flex-col;

    /* Styles to customize all HubSpot forms */
    .hbspt-form {
      @apply prose mx-auto col-span-12 overflow-hidden rounded-xl border bg-white lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3 p-8 md:p-16;

      h2 {
        @apply mb-2 mt-6 lg:mt-8;

        &:first-child {
          @apply  mt-2 lg:mt-2 !text-xl md:!text-2xl lg:!text-3xl;
        }
      }

      .hs-form {
        @apply flex flex-col gap-4 lg:gap-6;
      }

      .hs-form-field {
        label {
          @apply font-semibold text-sm font-display inline-flex gap-1;

          .hs-form-required {
            @apply text-sm text-negative-600;
          }
        }

        .hs-input  {
          @apply block w-full rounded border-0 px-4 py-2.5 leading-none text-gray-900 ring-1 ring-inset transition-all ring-border placeholder:font-normal placeholder:text-black-300 focus:ring-inset focus:ring-orange hover:ring-gray-700 focus:hover:ring-orange focus:outline-0 outline-0 sm:text-sm h-[58px];

          &.error {
            @apply ring-negative;
          }

          &[disabled] {
            @apply bg-background hover:ring-border;
          }
        }

        .hs-error-msgs {
          @apply list-none pl-0 my-0;

          li {
            @apply pl-0 mb-0;

            label {
                @apply mt-1 mb-0 text-sm text-negative-600 font-normal;
            }
          }
        }
      }

      .hs-fieldtype-textarea {
        textarea {
          @apply !h-[100px] py-4;
        }
      }

      .hs-submit {
        @aply border-t mt-6 flex items-center justify-start px-8 md:px-16 pt-8 pb-10;

        .actions {
          @apply inline-flex flex-col md:flex-row gap-4;
        }

        .hs-button[type="submit"] {
          @apply btn btn-green btn-lg font-medium inline-flex mr-auto !border-0 h-auto focus:ring-green hover:ring-green cursor-pointer;
        }
      }

      .hs-fieldtype-booleancheckbox {
        ul {
          @apply list-none pl-0 my-0;

          .hs-form-booleancheckbox {
            label {
              @apply relative flex items-start mt-6 gap-2;

              input {
                @apply h-6 w-6 rounded-sm !border !border-border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green grid place-items-center;

                + span {
                    @apply text-sm font-normal lg:translate-y-[.1rem] xl:gap-2 xl:inline-flex;
                }
              }
            }
          }
        }
      }

      fieldset {
        /* By some reason when in HabSpot form we have group of inputs in multiple columns it has max-width = 500px. We don't have to have this limitation.*/
        /* Note: In cases like this fieldset also has one of next clases: form-columns-0 or form-columns-1 or form-columns-2 or ... */
        max-width: none;
      }

      /* In HubSpot forms it is a checkbox-list where user can select multiple checkboxes for the same label */
      .hs-fieldtype-checkbox {
        ul {
          @apply list-none pl-0 my-0;

          .hs-form-checkbox {
            label {
              @apply relative flex items-start mt-6 gap-2;

              input {
                @apply h-6 w-6 rounded-sm !border !border-border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green grid place-items-center;

                + span {
                  @apply text-sm font-normal lg:translate-y-[.1rem] xl:gap-2 xl:inline-flex;
                }
              }
            }
          }
        }
        .hs-form-checkbox {

        }
      }

      .hs-fieldtype-radio {
        ul {
          @apply list-none pl-0 my-0 flex flex-col gap-2 lg:gap-3;

          .hs-form-radio {
            @apply my-0;

            label {
              @apply relative flex items-start gap-2 my-0;

              input {
                @apply h-6 w-6 rounded-full !border !border-border bg-white p-0.5 text-black transition-colors hover:border-green-300 focus:border-green focus:text-green grid place-items-center;

                + span {
                  @apply text-sm font-normal translate-y-[.1rem] gap-2 inline-flex;
                }
              }
            }
          }
        }
      }

      .hs_error_rollup {
        .hs-error-msgs {
          @apply list-none my-0 bg-negative-100 !px-4 !py-2 rounded-lg;

          li {
            @apply pl-0 my-0;

            label {
              @apply my-0 text-sm text-negative-600 font-normal;
            }
          }
        }
      }

      [type=radio] {
        &:checked {
          @apply !bg-green;

          background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e") !important;
          background-size: 1rem;
        }

        &:focus {
          @apply !ring-1 !ring-green/40 !border-green;
        }
      }

      [type=checkbox] {
        &:checked {
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI4LjIuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIzM3B4IiBoZWlnaHQ9IjMzcHgiIHZpZXdCb3g9IjAgMCAzMyAzMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzMgMzM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDA4NzY2O30KPC9zdHlsZT4KPGcgaWQ9IkNoZWNrIj4KCTxnIGlkPSJWZWN0b3IiPgoJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMi41LDI2LjJjLTAuMywwLTAuNS0wLjEtMC43LTAuM2wtNy03Yy0wLjQtMC40LTAuNC0xLDAtMS40czEtMC40LDEuNCwwbDYuMyw2LjNMMjcuOCw4LjUKCQkJYzAuNC0wLjQsMS0wLjQsMS40LDBzMC40LDEsMCwxLjRsLTE2LDE2QzEzLDI2LjIsMTIuNywyNi4yLDEyLjUsMjYuMnoiLz4KCTwvZz4KPC9nPgo8L3N2Zz4K') !important;
          background-size: 1rem;
        }

        &:focus {
          @apply !ring-0 !border-green;
        }
      }
    }
  }

  label {
    @apply mb-2 inline-block cursor-pointer text-base font-normal text-black;

    a {
      @apply text-green underline;
    }

    &.text-white A {
      color: #fff;
    }
  }

  .label-required {
    @apply after:pl-0.5 after:content-['*'] after:text-negative;
  }

  .form-input,
  input {
    @apply block w-full rounded border-0 px-4 py-2.5 leading-none text-gray-900 ring-1 ring-inset transition-all ring-border placeholder:font-normal placeholder:text-black-300 focus:ring-inset focus:ring-orange hover:ring-gray-700 focus:hover:ring-orange focus:outline-0 outline-0 sm:text-sm h-[58px];

    &[data-error="true"] {
      @apply ring-negative;
    }

    &[disabled] {
      @apply bg-background hover:ring-border;
    }
  }

  .form-input-lg {
    @apply text-sm lg:text-lg;
  }

  .form-input-xl {
    @apply text-sm lg:text-lg h-[72px] rounded-lg;
  }

  .form-error {
    @apply mt-1 text-sm text-negative-600;

    .text-white & {
      color: #fff;
    }
  }

  [type=checkbox]:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='green' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e") !important;
  }

  .radio-slider {
    @apply relative;

    &__item {
      @apply p-2 inline-flex justify-center items-center transition-all relative z-1;

      label {
        &::after {
          @apply content-[""] absolute inset-0 block w-full h-full rounded border border-dashed bg-white -z-10 transition;
        }

        &:hover {
          &::after {
            @apply border-green;
          }
        }
      }

      input {
        @apply hidden;

        &:checked {
          & + div label {
            &::after {
              @apply border-green bg-green;
            }
          }
        }
      }
    }
  }
}
