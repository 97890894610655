@layer components {
  .loader {
    path {
      @apply animate-spin;
      transform-origin: center center;
      animation-duration: 800ms !important;
      animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1) !important;

      &:nth-child(1) {
        animation-delay: 100ms !important;
      }
      &:nth-child(2) {
        animation-delay: 150ms !important;
      }
      &:nth-child(3) {
        animation-delay: 150ms !important;
      }
      &:nth-child(4) {
        animation-delay: 250ms !important;
      }
      &:nth-child(5) {
        animation-delay: 300ms !important;
      }
    }
  }
}
