@layer layer {
  .faq-category-wrapper {
    > a {
      @apply h-full;
    }
  }

  .faq-category-teaser {
    &:hover {
      .link {
        &::before {
          @apply !w-3;
        }
      }
    }
  }
}
