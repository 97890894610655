@layer components {
  .btn {
    @apply inline-flex h-14 items-center justify-center rounded-[56px] border border-transparent bg-gray-500 px-6 py-5 text-center text-sm font-normal leading-none text-white no-underline antialiased transition-colors duration-300 hover:bg-opacity-80;

    &:hover,
    &:focus {
      .btn__arrow {
        @apply ml-3 w-4 translate-x-0 opacity-75;
      }
    }

    &[disabled] {
      @apply pointer-events-none opacity-50;
    }

    &.is-loading {
      .btn__loader {
        @apply ml-3 w-4 translate-x-0 opacity-75;
      }
    }
  }

  .btn-lg {
    @apply h-[60px] px-8 py-6 text-lg leading-none;
  }

  .btn-xl {
    @apply h-[60px] lg:h-[77px] min-w-[220px] px-8 py-6 text-lg font-medium leading-none;
  }

  .btn-sm {
    @apply h-10 px-6 py-5 text-sm leading-none;
  }

  .btn-green {
    @apply bg-green;
  }

  .btn-yellow {
    @apply bg-yellow text-black;
  }

  .btn-yellow-light {
    @apply bg-yellow-300 text-black;
  }

  .btn-white {
    @apply bg-white text-black-400 hover:bg-yellow-200;
  }

  .btn-text {
    @apply bg-transparent text-black hover:text-green underline underline-offset-4;
  }

  .btn-outline {
    @apply bg-transparent;

    &.btn-green {
      @apply border-green text-green;
    }

    &.btn-white {
      @apply border-white text-white hover:bg-transparent;
    }
  }

  .btn__arrow,
  .btn__loader
  {
    @apply w-0 -translate-x-full overflow-hidden opacity-0 transition-all;
  }
}
