@layer components {
  .cta {
    &:hover {
      .cta__sprite {
        @apply opacity-40;

        path:nth-child(2) {
          transform: rotate(-20deg);
        }
        path:nth-child(4) {
          transform: rotate(30deg);
        }
        path:nth-child(5) {
          transform: rotate(-20deg);
        }
      }
    }

    .cta__sprite {
      path {
        transform-origin: center center;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
