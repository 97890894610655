@layer components {
  .logo {
    &:hover {
      .logo__lines {
        path:nth-child(1) {
          transform: rotate(-20deg);
        }
        path:nth-child(2) {
          transform: rotate(10deg);
        }
        path:nth-child(3) {
          transform: rotate(20deg);
        }
        path:nth-child(4) {
          transform: rotate(-25deg);
        }
        path:nth-child(5) {
          transform: rotate(15deg);
        }
      }
    }

    &__lines {
      path {
        transform-origin: center center;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
