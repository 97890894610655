@layer components {
  blockquote {
    @apply !text-orange !text-xl lg:!text-4xl font-bold font-display !leading-snug relative px-6 py-8 lg:px-16 lg:py-12;

    &::before,
    &::after {
      content: open-quote;
      background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='79.8px' height='50.5px' viewBox='0 0 79.8 50.5'%3E%3Cpath fill='%23ffe533' d='M18.8,0c9,0,16.5,6.3,18.3,14.7c3.4,15.2-7.1,28.6-19.8,35.8l1.4-12.9C8.4,37.6,0,29.2,0,18.8 C0,8.4,8.4,0,18.8,0L18.8,0z M60.9,0c9,0,16.5,6.3,18.3,14.7c3.4,15.2-7.1,28.6-19.8,35.8l1.4-12.9C50.5,37.6,42,29.2,42,18.8 C42,8.4,50.5,0,60.9,0L60.9,0z'/%3E%3C/svg%3E") 50% 50% no-repeat;
      @apply absolute bg-contain text-transparent w-10 h-8 lg:w-16 lg:h-12;
    }

    &::before {
      @apply top-0 left-0 rotate-180 -translate-x-full -translate-y-full scale-150 opacity-0;
    }

    &::after {
      @apply bottom-0 right-0 -rotate-180 translate-x-full translate-y-full scale-150 opacity-0;
    }

    &.in-viewport {
      &::before {
        @apply animate-blockquoteBefore;
        animation-fill-mode: forwards !important;
      }
      &::after {
        @apply animate-blockquoteAfter;
        animation-fill-mode: forwards !important;
      }
    }

    p {
      &::before, &::after {
        @apply !content-none;
      }
    }

    & + .blockquote__author {
      @apply text-sm lg:text-lg text-orange -mt-6 ml-6 lg:-mt-8 lg:ml-16 !mb-0 ;
    }
  }
}