@layer components {
  .has-sub-nav {
    &.is-active {
      > .sub-nav {
        @apply flex;
      }
    }

    > .sub-nav {
      @apply hidden flex-col gap-2;
    }

    > a {
      @apply inline-flex items-center gap-2;
    }
  }
}
