@layer layer {
  .default-teaser {
    &:hover {
      .link {
        &::before {
          @apply !w-3;
        }
      }
    }
  }

  .paragraph--teaser {
    .splide__track,
    .splide__list {
      @apply !overflow-visible;
    }

    .splide__slide {
      .paragraph {
        @apply !mb-0 h-full;

        .default-teaser {
          @apply h-full;
        }
      }
    }
  }
}
